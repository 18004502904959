import React from "react";
import withAdmin from "../admin.provider";
import BucketList from "./BucketList";

const Cameras = ({ cameras }) => {
  const deviceIds = cameras.map((camera) => camera.device_id);
  // const deviceIds = [1, 5, 20];
  return (
    <div id="cameras" className="admin-page">
      <div className="admin-page-header">
        <div className="header-title">カメラ</div>
      </div>
      <div className="admin-page-body">
        <div className="admin-content">
          <BucketList folders={deviceIds} />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(Cameras);
