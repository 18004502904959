import React, { useEffect } from "react";
import { message } from "antd";
import { useAtom } from "jotai";

import axios from "../../utilities/axios.utils";
import {
  usersList,
  organizationList,
  user,
  stationList,
  cameraList,
} from "../../store";
import { hasFeature } from "../../utilities/acl.utils";
import { ACL } from "../../constants";

const withAdmin = (Component) => {
  const AdminProvider = () => {
    const [organizations, getOrganizations] = useAtom(organizationList);
    const [users, getUsers] = useAtom(usersList);
    const [userData] = useAtom(user);
    const [stations, getStations] = useAtom(stationList);
    const [cameras, getCameras] = useAtom(cameraList);

    const fetchOrganizations = () => {
      if (!hasFeature(userData, ACL.FEATURES.MANAGE_ORGANIZATIONS)) {
        return;
      }

      getOrganizations();
    };

    const fetchUsers = () => {
      if (!hasFeature(userData, ACL.FEATURES.VIEW_USERS)) {
        return;
      }

      getUsers();
    };

    const fetchStations = () => {
      if (!hasFeature(userData, ACL.FEATURES.VIEW_STATIONS)) {
        return;
      }

      getStations();
    };

    const fetchCameras = () => {
      if (!hasFeature(userData, ACL.FEATURES.VIEW_IPCAMERAS)) {
        return;
      }

      getCameras();
    };

    // Organization Services
    const createOrganization = (organization) => {
      axios.post("/api/organizations", { ...organization }).then(() => {
        fetchOrganizations();
        message.success("New Organization Created!");
      });
    };

    const editOrganization = (id, organization) => {
      axios.put(`/api/organizations/${id}`, { ...organization }).then(() => {
        fetchOrganizations();
        message.success("Organization Updated!");
      });
    };

    const disableOrganization = (id) => {
      axios.delete(`/api/organizations/${id}`).then(() => {
        fetchOrganizations();
        message.success("Organization Deleted!");
      });
    };

    // User Services
    const createUser = (user) => {
      axios.post("/api/users", { ...user }).then(() => {
        getUsers();
        message.success("New User Created!");
      });
    };

    const editUser = (id, user) => {
      axios.put(`/api/users/${id}`, { ...user }).then(() => {
        getUsers();
        message.success("User Updated!");
      });
    };

    const disableUser = (id) => {
      axios.delete(`/api/users/${id}`).then(() => {
        getUsers();
        message.success("User Deleted!");
      });
    };

    // Station Services
    const createStation = (station) => {
      axios.post("/api/stations", { ...station }).then(() => {
        getStations();
        message.success("New Station Created!");
      });
    };

    const editStation = (id, station) => {
      axios.put(`/api/stations/${id}`, { ...station }).then(() => {
        getStations();
        message.success("Station Updated!");
      });
    };

    const disableStation = (id) => {
      axios.delete(`/api/stations/${id}`).then(() => {
        getStations();
        message.success("Station Deleted!");
      });
    };

    // Camera Services
    const createCamera = (cameras) => {
      axios.post("/api/ipcameras", { ...cameras }).then(() => {
        getCameras();
        message.success("New IPcamera Created!");
      });
    };

    const editCamera = (id, camera) => {
      axios.put(`/api/ipcameras/${id}`, { ...camera }).then(() => {
        getCameras();
        message.success("IPcamera Updated!");
      });
    };

    const disableCamera = (id) => {
      axios.delete(`/api/ipcameras/${id}`).then(() => {
        getCameras();
        message.success("IPcamera Deleted!");
      });
    };

    useEffect(() => {
      try {
        fetchOrganizations();
        fetchUsers();
        fetchStations();
        fetchCameras();
      } catch (e) {
        console.log(e);
        message.error("An error has occurred. Please try again.");
      }
    }, []);

    return (
      <Component
        organizations={organizations}
        users={users}
        stations={stations}
        cameras={cameras}
        createOrganization={createOrganization}
        editOrganization={editOrganization}
        disableOrganization={disableOrganization}
        createUser={createUser}
        editUser={editUser}
        disableUser={disableUser}
        createStation={createStation}
        editStation={editStation}
        disableStation={disableStation}
        createCamera={createCamera}
        editCamera={editCamera}
        disableCamera={disableCamera}
      />
    );
  };
  return AdminProvider;
};

export default withAdmin;
