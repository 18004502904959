import React, { useState, useEffect } from "react";
import { Card, CardHeader, Grid, CardContent, Button } from "@material-ui/core";
import { useAtom } from "jotai";
import axios from "../../../utilities/axios.utils";
import { stationData, stationList } from "../../../store";
import { Col, Divider, Row } from "antd";
import dayjs from "dayjs";
import { FaPencilAlt } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { Spin } from "antd";
import { AiOutlineLoading } from "react-icons/ai";

function SensorStatus() {
  const [stations] = useAtom(stationList);
  const [allStations, getAllStations] = useAtom(stationList);
  const [latest, setLatest] = useState([]);
  const [Data] = useAtom(stationData);
  const [loading, setLoading] = useState(false);

  const loraCommand = async (commandNo, device_id) => {
    await axios.get(`api/stations/${device_id}/command/${commandNo}`);
  };

  useEffect(() => {
    getAllStations();
  }, []);
  
  const refresh = () => {
    // setLoading(true);    //20231109 comment to prevent refresh at initial loading
    const fetchPromise = stations.map(async (station) => {
      let response = await axios.get(
        `/api/stations/${station.device_id}/latest`
      );
      return response.result;
    });
    Promise.all(fetchPromise).then((data) => {
      setLatest((latest) => {
        return [...latest, ...data];
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    if (stations) {
      refresh();
    }
  }, [stations]);

//  useEffect(() => {
    // Refresh regularly
//    setTimeout(() => {
//      refresh();
//    }, 10000);
//  }, []);

  useEffect(() => {
    // Refresh regularly
    const refreshTimeout = setTimeout(() => {
      refresh();
    }, 10000);

    return () => clearTimeout(refreshTimeout);
  }, []);

  const getDataInfo = (data, device_id, attrName) => {
    if (data.length) {
      const d = data.find((item) => item.device_id === device_id);
      if (d && d[attrName] !== undefined) {
        return d[attrName];
      }
      // return "N/A";
    }
  };

  return (
    <div className="admin-page">
      <div className="admin-page-body">
        {loading && (
          <div className="loader">
            <Spin className="rotate" indicator={<AiOutlineLoading />} />
          </div>
        )}
        {!loading && (
          <div className="bucket-content">

            <table className="test-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>最終データ送信時刻</th>
                  <th>Device Id</th>
                  <th>Error Code</th>
                  <th> RSSI</th>
                  <th>Gateway ID</th>
                  <th>接点出力1</th>
                  <th>接点出力2</th>
                  <th>接点出力 Status</th>
                  <th>Date </th>
                  <th>SS reset</th>

                </tr>
              </thead>
              {stations.map((station, idx) => (
                <tbody>
                  <tr key={idx} className="station_info">
                    <td className="text-align-left">
                      {station.deployed_location}{" "}
                    </td>
                    <td className="list">
                      {" "}
                      {getDataInfo(latest, station.device_id, "date_time")}
                    </td>
                    <td className="list">
                      {" "}
                      {getDataInfo(latest, station.device_id, "device_id")}
                    </td>
                    <td className="list">
                      {" "}
                      {getDataInfo(latest, station.device_id, "error_code")}
                    </td>
                    <td className="list">
                      {getDataInfo(latest, station.device_id, "rssi")}
                    </td>
                    <td className="list">
                      {" "}
                      {getDataInfo(latest, station.device_id, "gateway_id")}
                    </td>
                    {(() => {
                      var pan = getDataInfo(
                        latest,
                        station.device_id,
                        "pan_id"
                      );
                      var h1_shitsuryoku = Number(
                        getDataInfo(
                          latest,
                          station.device_id,
                          "h1_shitsuryoku_status"
                        )
                      );
                      var h2_shitsuryoku = Number(
                        getDataInfo(
                          latest,
                          station.device_id,
                          "h2_shitsuryoku_status"
                        )
                      );
		      var h2 = getDataInfo(
                          latest,
                          station.device_id,
                          "h2_angle"
                        )
                      
                      if (pan !== "0003" && pan === "0001") {
                        return (
                          <>
                            <td className="list">
                              {h1_shitsuryoku ? " 順流" : " -"}
                            </td>
                            <td className="list">
                              {h2_shitsuryoku ? " 逆流" : " -"}
                            </td>
                            <td className="list">
                              {getDataInfo(
                                latest,
                                station.device_id,
                                "SS_Reset_Status"
                              )
                                ? "ON"
                                : "OFF"}
                            </td>
                          </>
                        );
                      } else if (pan !== "0003" && pan === "0002" && h2 !== "****") {
                        return (
                          <>
                            <td className="list">
                              {h1_shitsuryoku ? " 開＋逆" : " -"}
                            </td>
                            <td className="list">
                              {h2_shitsuryoku ? " 閉＋（逆 / 順）" : " -"}
                            </td>
                            <td className="list">
                              {getDataInfo(
                                latest,
                                station.device_id,
                                "SS_Reset_Status"
                              )
                                ? "ON"
                                : "OFF"}
                            </td>
                          </>
                        );
                      } else if (pan !== "0003" && pan === "0002" && h2 === "****") {
                        return (
                          <>
                            <td className="list">
                              {h1_shitsuryoku ? " 閉" : " 開"}
                            </td>
                            <td className="list">
                              {" -"}
                            </td>
                            <td className="list">
                              {getDataInfo(
                                latest,
                                station.device_id,
                                "SS_Reset_Status"
                              )
                                ? "ON"
                                : "OFF"}
                            </td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td>..</td>
                            <td>.. </td>
                            <td>.. </td>
                          </>
                        );
                      }
                    })()}
                    <td className="list">
                      <Button
                        variant="contained"
                        color={
                          station.date_reset === false ? "primary" : "secondary"
                        }
                        onClick={() => {
                          loraCommand("date_reset", station.device_id);
                          window.location.reload(false);
                        }}
                      >
                        Date Reset
                      </Button>
                    </td>
                    {(() => {
                      var pan = getDataInfo(
                        latest,
                        station.device_id,
                        "pan_id"
                      );
                      if (pan !== "0003") {
                        return (
                          <td className="list">
                            <Button
                              variant="contained"
                              color={
                                station.ss_reset === false
                                  ? "primary"
                                  : "secondary"
                              }
                              onClick={() => {
                                loraCommand("ss_reset", station.device_id);
                                window.location.reload(false);
                              }}
                            >
                              SS Reset
                            </Button>
                          </td>
                        );
                      } else {
                        return "";
                      }
                    })()}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
export default SensorStatus;
