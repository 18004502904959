import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, message, Button } from "antd";

import { ACL } from "../../../constants";
import { useAtom } from "jotai";
import { user } from "../../../store";
import { hasFeature } from "../../../utilities/acl.utils";

const OrganizationModal = ({
  show,
  onSubmit,
  onCancel,
  data,
  organizations,
}) => {
  const [form] = Form.useForm();
  const [userData] = useAtom(user);
  const [showPasswordField, setShowPasswordField] = useState(false);

  let allRoles = Object.values(ACL.ROLES).map((feature) => ({
    value: feature,
    label: feature,
  }));
  if (!hasFeature(userData, ACL.FEATURES.ALL_USERS)) {
    allRoles = allRoles.filter((role) => role.label !== ACL.ROLES.ROOT);
  }

  // List of allowed email providers (e.g., gmail, outlook)
  const allowedProviders = ["gmail.com", "outlook.com", "takuwa.co.jp"];

  const placeholder = `Enter your email (${allowedProviders.join(", ")})`; // Generate placeholder

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        const emailProvider = values.email.split("@")[1]; // Extract the email provider
        if (!allowedProviders.includes(emailProvider)) {
          message.error(
            "Registration is only allowed with specific email providers."
          );
          return;
        }
        onSubmit(values);
      })
      .catch((err) => {
        console.log("form err", err);
      });
  };

  //20240119
  const validateEmail = (rule, value) => {
  // Regular expression to allow only ASCII characters, half-width alphabet, and numbers
    const regex = /^[ -~]+$/;

    if (!regex.test(value)) {
      return Promise.reject('Please enter a valid email address with only ASCII characters, half-width alphabet, and numbers!');
    } 

    return Promise.resolve();
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Modal
      centered
      title="ユーザー登録/編集"
      className="custom-modal _user"
      open={show}
      onOk={submit}
      onCancel={onCancel}
      closable={false}
      okText={data ? "更新" : "登録"}
    >
      <Form form={form} layout="vertical" initialValues={data}>
        <Form.Item label="名" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {
          <Form.Item
            label="Eメール"
            name="email"
            rules={[
              { required: true },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
	      { validator: validateEmail },
            ]}
          >
            <Input placeholder={placeholder} />
          </Form.Item>
        }

        {!data && (
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message:
                  "Please enter a valid half-width alphanumeric character.",
                pattern: /^[a-zA-Z0-9]+$/,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}

        {/* Conditionally render the "Change Password" button in edit mode */}
        {data && (
          <Form.Item>
            <Button
              type="link"
              onClick={() => setShowPasswordField(!showPasswordField)}
            >
              {showPasswordField ? "Hide Password" : "Change Password"}
            </Button>
          </Form.Item>
        )}
        {/* Conditionally render the password field in edit mode */}
        {data && showPasswordField && (
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: showPasswordField,
                message:
                  "Please enter a valid half-width alphanumeric character.",
                pattern: /^[a-zA-Z0-9]+$/,
              },
            ]}
          >
            <Input.Password placeholder="Leave empty to keep unchanged" />
          </Form.Item>
        )}

        {hasFeature(userData, ACL.FEATURES.ALL_USERS) && (
          <Form.Item
            label="グループ"
            name="organizationId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="グループ選択してください"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={organizations.map((o) => ({
                value: o.id,
                label: o.name,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item label="役割" name="role" rules={[{ required: true }]}>
          <Select placeholder="役割選択してください" options={allRoles} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OrganizationModal;
