import React, { useEffect, useState } from "react";
import { Button, Input, Table, Dropdown, Tooltip, Modal } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdClear } from "react-icons/md";

import UsersModal from "./UsersModal";
import withAdmin from "../admin.provider";

const { Search } = Input;
const { confirm } = Modal;
const Users = ({ createUser, users, editUser, organizations, disableUser }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUserID, setEditUserID] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getDropdownItems = (id) => ({
    items: [
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              setEditUserID(id);
              setShowEditModal(true);
            }}
          >
            編集
          </div>
        ),
        key: `edit-org-${id}`,
      },
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              confirm({
                title: "Are you sure?",
                content: "Are you sure you want to delete this user?",
                onOk() {
                  disableUser(id);
                },
              });
            }}
          >
            削除
          </div>
        ),
        key: `delete-user-${id}`,
      },
    ],
  });

  const columns = [
    {
      title: "名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Eメール",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "グループ",
      dataIndex: ["organization", "name"],
      key: "organization",
    },
    {
      title: "役割",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text, record) => (
        <Dropdown
          overlayClassName="list-actions"
          menu={getDropdownItems(record.id)}
          trigger={["click"]}
        >
          <span className="actions-btn">
            <BiDotsVerticalRounded />
          </span>
        </Dropdown>
      ),
    },
  ];

  const filter = (term) => {
    if (!term) {
      setFilteredList(users);
      setSearchValue("");
    } else {
      setFilteredList(() =>
        users.filter(
          (user) =>
            user.name.toLowerCase().includes(term.toLowerCase()) ||
            user.email.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    filter(searchValue);
    setFilteredList(users);
  }, [users]);

  return (
    <div id="users" className="admin-page">
      {/* Conditional rendering expression using the logical AND (&&) operator?
      If showCreateModal is true, the code inside the ... will be executed,
      which renders the UsersModal component. */}
      {showCreateModal && (
        <UsersModal
          show={showCreateModal}
          organizations={organizations || []}
          onCancel={() => setShowCreateModal(false)}
          onSubmit={(values) => {
            createUser(values);
            setShowCreateModal(false);
          }}
        />
      )}

      {showEditModal && (
        <UsersModal
          show={showEditModal}
          organizations={organizations || []}
          data={users.find((org) => org.id === editUserID)}
          onCancel={() => setShowEditModal(false)}
          onSubmit={(values) => {
            editUser(editUserID, values);
            setShowEditModal(false);
            setEditUserID(null);
          }}
        />
      )}
      <div className="admin-page-header">
        <div className="header-title">ユーザー</div>
        <div className="header-description">ユーザー管理</div>
      </div>
      <div className="admin-page-body">
        <div className="admin-toolbar">
          <Button type="primary" onClick={() => setShowCreateModal(true)}>
            新規ユーザ登録
          </Button>
          <div className="search-input">
            <Search
              placeholder="検索ユーザー"
              onSearch={filter}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          {!!searchValue && (
            <Tooltip title="クリア検索">
              <span className="clear-search" onClick={() => filter()}>
                <MdClear />
              </span>
            </Tooltip>
          )}
        </div>
        <div className="admin-content">
          <Table
            dataSource={filteredList}
            columns={columns}
            pagination={{ pageSize: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(Users);
