import React from 'react';
import { useAtom } from 'jotai';
import { Redirect, Route } from 'react-router-dom';

import { user } from '../store';

const PrivateRoute = ({ children, ...rest }) => {
  const [userData] = useAtom(user);
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          userData
            ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location }
                }}
              />
            ))
      }
    />
  );
};

export default PrivateRoute;
