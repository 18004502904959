import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { stationData } from "../store";
import AWS from "aws-sdk";
import { Card, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getS3Client } from '../utilities/bucket.utils'; //20240119

//const s3 = new AWS.S3({
//  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
//  secretAccessKey: process.env.REACT_APP_S3_SECRET,
//  region: "ap-northeast-1",
//});

let s3; // Declare s3 at the top level of the file
//async () => {
//  try {
//    const credentials = await getS3Client();
//    s3 = new AWS.S3({
//      credentials: credentials,
//      region: "ap-northeast-1",
//    });

//    console.log('ReactS3:', credentials);
//  } catch (error) {
//    console.error('Error initializing S3:', error);
//  }
//})();

const IPcamera = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [Data] = useAtom(stationData);
  const history = useHistory();
 // const [selectedDate] = useState(new Date());
  //const currentDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
  const folder= Data.device_id;

  useEffect(() => {
    const initializeS3 = async () => {
      try {
        const credentials = await getS3Client();
        s3 = new AWS.S3({
          credentials: credentials,
          region: "ap-northeast-1",
        });

        console.log('ReactS3:', credentials);
      } catch (error) {
        console.error('Error initializing S3:', error);
      }
    };

    initializeS3(); // Call the async function inside useEffect
  }, []);

  useEffect(() => {
    console.log("Component mounted");
    let isMounted = true; // Add a variable to track if the component is mounted

    const getLatestImage = async () => {
      console.log("Fetching latest image...");
      try {
        const response = await s3
          .listObjectsV2({
            Bucket: process.env.REACT_APP_S3_BUCKET,
            //Prefix: Data.device_id + "/" + currentDate + "/",
            Prefix: folder + "/",
          })
          .promise();

        if (isMounted && response.Contents.length > 0) {
          // Check if the component is still mounted before updating state
          const latestImage = response.Contents[response.Contents.length - 1];
          setImageUrl(
            s3.getSignedUrl("getObject", {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: latestImage.Key,
            })
          );
          console.log("Latest image fetched successfully");
        } else {
          console.log("No images found in S3 bucket");
             // Set imageUrl state to "No image found"
             setImageUrl("No image found");
        }
      } catch (error) {
        console.log("Error fetching latest image:", error);
      }
    };

    getLatestImage();

    return () => {
      console.log("Component unmounted");
      isMounted = false; // Set the variable to false to indicate that the component is unmounted
    };
  }, [folder]);

  //if camera is installed then onclick goes to the IPcamera.js
  const handleClick = () => {
    setSelectedImageUrl(imageUrl);
    history.push(`/admin/cameras/IPcameraHIstory/${folder}`, "_blank");
  };

  const handleNewWindowLoad = (event) => {
    event.source.postMessage(selectedImageUrl, event.origin);
  };

  return (
    //if the camera if not installed then handleclick will not work
    <Card className="image_area" onClick={imageUrl !== 'No image found' ? handleClick : null}> 
      {/* <Grid
        container
        direction="column"
        item
        xs={12}
        justifyContent="space-between"
      > */}
        <div style={{ width:'335px', height: '435px', backgroundColor: '#f1f1f1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
         {imageUrl === 'No image found' ? (<p>Station {folder} カメラが設置されていません</p>) : (<img src={imageUrl}  />)}
       </div>      {/* </Grid> */}
       {selectedImageUrl && (
          <iframe
            src={`/admin/cameras/IPcameraHIstory/${selectedImageUrl}`}
            style={{ display: 'none' }}
            onLoad={handleNewWindowLoad}
          />
        )}
    </Card>
  );
};

export default IPcamera;
