import { atom } from "jotai";

import axios from "./utilities/axios.utils";

export const stations = atom([]);
export const stationDataAtom = atom([]);
export const selectedStationAtom = atom(null);
export const SelectedStationHistoryDataAtom = atom([]);
export const notification = atom([]);
export const user = atom(null);
export const appLoadingAtom = atom(true);
export const organizationDataAtom = atom([]);
export const usersDataAtom = atom([]);
export const cameras = atom([]);
export const camerasDataAtom = atom([]);

export const stationList = atom(
  (get) => get(stations),
  async (_get, set, args) => {
    const data = await axios.get("api/stations/all");
    set(stations, data.result);
  }
);

export const cameraList = atom(
  (get) => get(cameras),
  async (get, set) => {
    const data = await axios.get("api/ipcameras/all");
    set(cameras, data.result);
  }
);

export const stationData = atom(
  (get) => get(stationDataAtom),
  async (_get, set, args) => {
    if (args) {
      const data = await axios.get(`api/stations/${args}/latest`);
      set(stationDataAtom, data.result);
    }
  }
);

export const stationHistoryData = atom(
  (get) => get(SelectedStationHistoryDataAtom),
  async (_get, set, args) => {
    if (args) {
      const data = await axios.get(`api/stations/${args}/recent/1`);
      set(SelectedStationHistoryDataAtom, data.result);
    }
  }
);

export const organizationList = atom(
  (get) => get(organizationDataAtom),
  async (get, set) => {
    const data = await axios.get("api/organizations");
    set(organizationDataAtom, data.organizations);
  }
);

export const usersList = atom(
  (get) => get(usersDataAtom),
  async (get, set) => {
    const data = await axios.get("api/users");
    set(usersDataAtom, data.users);
  }
);
