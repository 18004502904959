import React from "react";
import { useAtom } from "jotai";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { stationData } from "../store";
import TSFDbottomsensor from "./TSFD-bottom-sensor";
import ContactSignal from "./contactSignal";
import { getStatusCard, CustomCardHeader } from "./commonComponents";

function TAGF() {
  const [Data] = useAtom(stationData);
  return (
    <>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={10}
          justifyContent="space-evenly"
        >
          <Grid item xs={3}>
            <Grid container direction="row" item xs={12}>
              <CustomCardHeader
                title={
                  <Typography variant="body1" className="databox-font">
                    ゲート状態
                  </Typography>
                }
              />
            </Grid>
          </Grid>

          <Grid container direction="row" item xs={7}>
            <Grid container direction="row" item xs={12}>
              {["1", "0"].map((gate_status) => (
                <Grid container item xs={4} key={gate_status}>
                  {getStatusCard(
                    Data.h1_flow_direction === gate_status
                      ? gate_status === "1"
                        ? "#87A4D9"
                        : gate_status !== "1"
                        ? "#87A4D9"
                        : "white"
                      : "white",
                    gate_status === "1" ? "全開" : "全閉"
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <div className="divider"></div>
      {/* // bottom Sensor */}
      {Data.h2_angle == "****" ? (
        ""
      ) : (
        <>
          <TSFDbottomsensor />
        </>
      )}
      {/* contact signal output */}
      <ContactSignal />
    </>
  );
}

export default TAGF;
