import axios from "axios";
import { message } from "antd";

import { GLOBAL } from "../constants";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    // Try to get token from cookie
    const token = window.sessionStorage.getItem(GLOBAL.TOKEN_COOKIE_NAME);

    if (token) {
      config.headers["Authorization"] = token;
    }

    instance.defaults.headers.post["Content-Type"] =
      "application/json; charset=UTF-8";
    return config;
  },
  (error) => {
    message.error(error.message);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  ({ data }) => {
    return data;
  },
  (error) => {
    console.log("Request failed", error);
    message.error(error?.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

export default instance;
