import React from "react";
// import SlideImage2 from "../components/SlideImage2"; //add
// import Image from "../components/Image";
//--tab実験----------------↓--------------npm install @material-ui/core でライブラリインストール
import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import "./ImageTab.css";
import Image from "./Image";
import IPcamera from "./IPcamera";
import BucketList from "../views/Admin/Camera/BucketList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return <div {...other}>{value === index && <Box p={2}>{children}</Box>}</div>;
}
//-----------------------------↑----------------------------------------------------------

function SlideImage() {
  /*---tab test --↓------------*/
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /*---tab test ---↑-----------*/

  return (
    <>
      <AppBar
        position="static"
        style={{ color: "#000000", backgroundColor: "#a8d0ec" }}
      >
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="リアルタイム画像" />
          <Tab label="設置写真" />
        </Tabs>
      </AppBar>
      <div className="panelheight">
        <TabPanel value={value} index={0} className="tab1">
          <IPcamera />

          {/* <BucketList /> */}
        </TabPanel>
        <TabPanel value={value} index={1} className="tab2">
          <Image />
        </TabPanel>
      </div>
    </>
  );
}

export default SlideImage;
