import React from "react";
import { useAtom } from "jotai";
import { CardContent, Typography, Grid } from "@material-ui/core";
import { stationData } from "../store";
import { getStatusCard, CustomCardHeader } from "./commonComponents";

function ContactSignal() {
  const [Data] = useAtom(stationData);

  return (
    <>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={10}
          justifyContent="space-evenly"
        >
          <Grid item xs={3}>
            <CustomCardHeader
              title={
                <Typography variant="body1" className="databox-font">
                  接点出力
                </Typography>
              }
            />
          </Grid>

          <Grid container direction="row" item xs={7}>
            <Grid container item xs={4}>
              {getStatusCard(
                Data.SS_Reset_Status === 1 ? "#AFD498" : "#BFBFBF",
                Data.SS_Reset_Status === 1 ? "ON" : "OFF"
              )}
            </Grid>

            <Grid container item xs={4}>
              {getStatusCard(
                Data.SS_Reset_Status === 1 && Data.h1_shitsuryoku_status === "1" && Data.pan_id === "0001"
                  ? "#87A4D9"
                  : Data.SS_Reset_Status === 1 && Data.h1_shitsuryoku_status === "0" &&
                    Data.pan_id === "0002" &&
                    Data.h2_angle === "****"
                  ? "#87A4D9"
                  : Data.SS_Reset_Status === 1 && Data.h1_shitsuryoku_status === "1" &&
                    Data.pan_id === "0002" &&
                    Data.h2_angle !== "****"
                  ? "#F3AD7D"
                  : "white",
                Data.pan_id === "0001"
                  ? "順流"
                  : Data.pan_id === "0002" && Data.h2_angle === "****"
                  ? "全開"
                  : "全開+逆"
              )}
            </Grid>
            <Grid container item xs={4}>
              {/* {getStatusCard(
                Data.h2_shitsuryoku_status === "1" && Data.pan_id === "0002"
                  ? "#87A4D9"
                  : Data.h2_shitsuryoku_status === "1" && Data.pan_id === "0001"
                  ? "#F3AD7D"
                  : "white",
                Data.pan_id === "0001" ? "逆流" : " 全閉[順/逆]"
              )} */}
              {getStatusCard(
                (Data.SS_Reset_Status === 1 && Data.h2_shitsuryoku_status === "1" &&
                  Data.pan_id === "0002" &&
                    Data.h2_angle !== "****")||
                  (Data.SS_Reset_Status === 1 && Data.h2_shitsuryoku_status === "1" &&
                    Data.pan_id === "0002" &&
                    Data.h2_angle !== "****") ||
                  (Data.SS_Reset_Status === 1 && Data.h1_shitsuryoku_status === "1" &&
                    Data.pan_id === "0002" &&
                    Data.h2_angle === "****") //20231213
                  ? "#87A4D9"
                  : Data.h2_shitsuryoku_status === "1" && Data.pan_id === "0001"
                  ? "#F3AD7D"
                  : "white",
                Data.pan_id === "0001"
                  ? "逆流"
                  : Data.pan_id === "0002" && Data.h2_angle !== "****"
                  ? "全閉[順/逆]"
                  : "全閉"
                // : "全閉[順/逆]"
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default ContactSignal;
