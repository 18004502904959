import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Tag, message } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import emailRegexSafe from "email-regex-safe";

const OrganizationModal = ({ show, onSubmit, onCancel, data }) => {
  const [form] = Form.useForm();
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState(false);

  const submit = () => {
    // const values = form.getFieldsValue();
    // values.email_recipients = emails;
    // onSubmit(values);

    form
      .validateFields()
      .then((values) => {
        values.email_recipients = emails;
        onSubmit(values);
      })
      .catch((err) => {
        console.log("form err", err);
      });
  };

  const handleNewEmailKeyUp = (event) => {
    if (event.key === "Enter") {
      const val = event.target.value;
      console.log(emailRegexSafe(), val, val.match(emailRegexSafe()));
      if (!emailRegexSafe().test(val)) {
        message.error("Invalid Email");
        return;
      }

      if (emails.includes(val)) {
        message.error("Email already exists");
        return;
      }

      setEmails((oldEmails) => [...oldEmails, event.target.value]);
      setNewEmail(false);
    }
  };

  const removeEmail = (email) => {
    setEmails((oldEmails) => {
      const emailIndex = emails.findIndex((_email) => _email === email);
      if (emailIndex > -1) {
        return [...oldEmails.splice(emailIndex, 1)];
      }

      return oldEmails;
    });
  };

  //20240119
  const validateEmail = (rule, value) => {
  // Regular expression to allow only ASCII characters, half-width alphabet, and numbers
    const regex = /^[ -~]+$/;

    if (!regex.test(value)) {
      return Promise.reject('Please enter a valid email address with only ASCII characters, half-width alphabet, and numbers!');
    }  

    return Promise.resolve();
  };


  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setEmails(data.email_recipients || []);
    }
  }, [data]);

  return (
    <Modal
      centered
      // destroyOnClose
      title="グループ登録/編集"
      className="custom-modal"
      open={show}
      onOk={submit}
      onCancel={onCancel}
      closable={false}
      okText={data ? "編集" : "登録"}
    >
      <Form form={form} layout="vertical" initialValues={data}>
        <Form.Item label="名" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
       <Form.Item
          label="Eメール"
          name="email"
          rules={[
            { required: true },
            { type: "email", message: "Please enter a valid email address!" },
//20240126 Added after evaluation test was done
	    { validator: validateEmail },
          ]}
       >
          <Input />
        </Form.Item>

        <Form.Item label="住所" name="address">
          <Input />
        </Form.Item>
        <Form.Item
          label="信号異常の通信間隔 (分)"
          name="communication_interval"
	  rules={[
           { required: true },
           {
//20240126 Added after evaluation test was done
             pattern: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2})$/,
             message: "Please enter between 1 to 999.",
           },
          ]}

        >
          <Input />
        </Form.Item>
      </Form>
      <div className="form-email-recipients">
        <div className="label">Emails</div>
        <div className="values">
          {emails.map((email) => (
            <Tag closable key={email} onClose={() => removeEmail(email)}>
              <span>{email}</span>
            </Tag>
          ))}
          <div className="new-email">
            {newEmail ? (
              <Input
                onKeyUp={(e) => handleNewEmailKeyUp(e)}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
	          { validator: validateEmail },
                ]}
              />
            ) : (
              <Tag role="button" onClick={() => setNewEmail(true)}>
                <AiOutlinePlus />
                <span>New Email</span>
              </Tag>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationModal;
