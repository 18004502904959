import React, { useState } from "react";
import { Button, Input, Table, Dropdown, Modal } from "antd";
import CameraModal from "./CamerasModal";
import withAdmin from "../admin.provider";
import { BiDotsVerticalRounded } from "react-icons/bi";

const { confirm } = Modal;

const CameraList = ({
  cameras,
  organizations,
  createCamera,
  editCamera,
  disableCamera,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCameraID, setEditCameraID] = useState(null);
  // const [filteredList, setFilteredList] = useState([]);
  // const [searchValue, setSearchValue] = useState("");

  const getDropdownItems = (id) => ({
    items: [
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              setEditCameraID(id);
              setShowEditModal(true);
            }}
          >
            編集
          </div>
        ),
        key: `edit-org-${id}`,
      },
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              confirm({
                title: "Are you sure?",
                content: "Are you sure you want to delete this IPcamera?",
                onOk() {
                  disableCamera(id);
                },
              });
            }}
          >
            削除
          </div>
        ),
        key: `delete-camera-${id}`,
      },
    ],
  });

  const columns = [
    {
      title: "デバイス ID",
      dataIndex: "device_id",
      key: "device_id",
    },
    {
      title: "ユーザー名",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "IPアドレス",
      dataIndex: "ip_add",
      key: "ip_add",
    },
    // {
    //   title: "Interval",
    //   dataIndex: "ip_interval",
    //   key: "ip_interval",
    // },
    {
      title: "グループ",
      dataIndex: ["organization", "name"],
      key: "organization",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text, record) => (
        <Dropdown
          overlayClassName="list-actions"
          menu={getDropdownItems(record.device_id)}
          trigger={["click"]}
        >
          <span className="actions-btn">
            <BiDotsVerticalRounded />
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <div id="cameras">
      {showCreateModal && (
        <CameraModal
          show={showCreateModal}
          organizations={organizations || []}
          onCancel={() => setShowCreateModal(false)}
          onSubmit={(values) => {
            createCamera(values);
            setShowCreateModal(false);
          }}
        />
      )}
      {showEditModal && (
        <CameraModal
          show={showEditModal}
          organizations={organizations || []}
          data={cameras.find((camera) => camera.device_id === editCameraID)}
          onCancel={() => setShowEditModal(false)}
          onSubmit={(values) => {
            editCamera(editCameraID, values);
            setShowEditModal(false);
            setEditCameraID(null);
          }}
        />
      )}
      <div className="admin-page-body">
        <div className="admin-toolbar">
          <Button type="primary" onClick={() => setShowCreateModal(true)}>
            新規カメラ登録
          </Button>
        </div>
        <div className="admin-content">
          <Table
            dataSource={cameras}
            columns={columns}
            pagination={{ pageSize: 3 }}
            // scroll={{
            //   y: 100,
            // }}
          />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(CameraList);
