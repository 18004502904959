import React, { useEffect, useState } from "react";
import { Button, Input, Table, Dropdown, Tooltip, Modal } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdClear } from "react-icons/md";

import OrganizationModal from "./OrganizationModal";
import withAdmin from "../admin.provider";

const { Search } = Input;
const { confirm } = Modal;

const Organizations = ({
  createOrganization,
  organizations,
  editOrganization,
  disableOrganization,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editOrganizationID, setEditOrganizationID] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getDropdownItems = (id) => ({
    items: [
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              setEditOrganizationID(id);
              setShowEditModal(true);
            }}
          >
            編集
          </div>
        ),
        key: `edit-org-${id}`,
      },
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              confirm({
                title: "Are you sure?",
                content: "Are you sure you want to delete this organization?",
                onOk() {
                  disableOrganization(id);
                },
              });
            }}
          >
            削除
          </div>
        ),
        key: `delete-org-${id}`,
      },
    ],
  });

  const columns = [
    {
      title: "名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Eメール",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "住所",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "通信間隔(分)",
      dataIndex: "communication_interval",
      key: "communication_interval",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text, record) => (
        <Dropdown
          overlayClassName="list-actions"
          menu={getDropdownItems(record.id)}
          trigger={["click"]}
        >
          <span className="actions-btn">
            <BiDotsVerticalRounded />
          </span>
        </Dropdown>
      ),
    },
  ];

  const filter = (term) => {
    if (!term) {
      setFilteredList(organizations);
      setSearchValue("");
    } else {
      setFilteredList(() =>
        organizations.filter(
          (org) =>
            org.name.toLowerCase().includes(term.toLowerCase()) ||
            org.email.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  };
  useEffect(() => {
    filter();
  }, [organizations]);

  return (
    <div id="organizations" className="admin-page">
      {/* <OrganizationModal
        show={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onSubmit={(values) => {
          createOrganization(values);
          setShowCreateModal(false);
        }}
      />
      <OrganizationModal
        show={showEditModal}
        data={organizations.find((org) => org.id === editOrganizationID)}
        onCancel={() => setShowEditModal(false)}
        onSubmit={(values) => {
          editOrganization(editOrganizationID, values);
          setShowEditModal(false);
          setEditOrganizationID(null);
        }}
      /> */}
      {showCreateModal && (
        <OrganizationModal
          show
          onCancel={() => setShowCreateModal(false)}
          onSubmit={(values) => {
            createOrganization(values);
            setShowCreateModal(false);
          }}
        />
      )}
      {showEditModal && (
        <OrganizationModal
          show
          data={organizations.find((org) => org.id === editOrganizationID)}
          onCancel={() => setShowEditModal(false)}
          onSubmit={(values) => {
            editOrganization(editOrganizationID, values);
            setShowEditModal(false);
            setEditOrganizationID(null);
          }}
        />
      )}
      <div className="admin-page-header">
        <div className="header-title">グループ</div>
        <div className="header-description">グループ管理</div>
      </div>
      <div className="admin-page-body">
        <div className="admin-toolbar">
          <Button type="primary" onClick={() => setShowCreateModal(true)}>
            新グループ登録
          </Button>
          <div className="search-input">
            <Search
              placeholder="グループ検索"
              onSearch={filter}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          {!!searchValue && (
            <Tooltip title="クリア検索">
              <span className="clear-search" onClick={() => filter()}>
                <MdClear />
              </span>
            </Tooltip>
          )}
        </div>
        <div className="admin-content">
          <Table
            dataSource={filteredList}
            columns={columns}
            // pagination={{ position: ["none", "none"] }}
            pagination={{ pageSize: 5 }}
          />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(Organizations);
