import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { LinearProgress } from "@material-ui/core";
import { ACL } from "../../../constants";
import { useAtom } from "jotai";
import { user } from "../../../store";
import { hasFeature } from "../../../utilities/acl.utils";
import { getS3Client } from '../../../utilities/bucket.utils'; //20240119

{/*const config = {
  bucketName: process.env.REACT_APP_Web_S3_BUCKET,
  region: "ap-northeast-1",
};

const ReactS3Client = new AWS.S3(config);*/}

const OrganizationModal = ({
  show,
  onSubmit,
  onCancel,
  data,
  organizations,
}) => {
  const [form] = Form.useForm();
  const [userData] = useAtom(user);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [imageOne, setImageOne] = useState();
  const [imageTwo, setImageTwo] = useState();
  // Use a temporary variable to store the URL
  let temporaryUrl;
  // 2023/9/14 Add start
  const [display, setdisplay] = useState(false);
  const standardDisplay = (model) => {
    // console.log(model);
    if (model === "河床堆積量計") {
      // true
      setdisplay(true);
    } else {
      // false
      setdisplay(false);
    }
    // console.log(display);
  };

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        // onSubmit(values);
        onSubmit({
          ...values,
          newImage1: imageOne,
          newImage2: imageTwo,
        });
      })
      .catch((err) => {
        console.log("form err", err);
      });
  };

  const uploadToBucket = async (file) => {
    try {
      const credentials = await getS3Client();

    // Check if credentials are available
      if (credentials) {
      // Create an instance of AWS.S3 using the obtained credentials
        const s3 = new AWS.S3({
          credentials: credentials,
          region: 'ap-northeast-1', // Replace with your S3 bucket's region
        });

        console.log('ReactS3Client:', credentials);
      // Create a unique key based on the current date and time
      const now = new Date();
//      const key = `uploads/${now.getFullYear()}/${now.getMonth() + 1}/${now.getTime()}_${uuidv4()}${path.extname(file.originalname)}`;


//      const key = `uploads/${now.getFullYear()}/${now.getMonth() + 1}/${now.getTime()}_${file.originalname}`;
      const key = `uploads/${file.name}`;
      // Use the s3 instance to upload the file
        const params = {
          Bucket: process.env.REACT_APP_Web_S3_BUCKET, // Replace with your S3 bucket name
          Key: key, // Replace with the desired key/name for the file
          Body: file,
	  ContentType:file.type, 
         };

        const res = await s3.upload(params).promise();

        console.log('File uploaded successfully:', res.Location);
        return res.Location;
      } else {
        console.log('No valid credentials available for S3 upload.');
        return null;
      }
    } catch (err) {
      console.error('Error uploading file:', err);
      throw err;
    }
  };

 // const uploadToBucket = async(file) =>{
 //   new Promise((resolve, reject) => {
 //     const ReactS3Client = await getS3Client();
 //     ReactS3Client.uploadFile(file)
 //       .then((res) => {
 //         resolve(res.location);
 //       })
 //       .catch((err) => {
 //         reject(err);
 //       });
 //   });
 // };

  const uploadImageOne = (event) => {
    setIsUploadingFiles(true);
    const file = event.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 1024 * 1024; // 1MB

    if (fileSizeInBytes > maxSizeInBytes) {
      // File size exceeds the limit
      alert("Please select an image file smaller than 1MB.");
      return;
    }
    uploadToBucket(file)
      .then((thumb) => {
        setIsUploadingFiles(false);
	//setImageOne({ url: thumb, alt: "Image Alt Text" });
	// Add this log to check the value of thumb before setting the state
        console.log("Thumb before setting state:", thumb);
        setImageOne(thumb);
	// Add this log to check the value of ImageOne after setting the state
        console.log("ImageOne after setting state:", imageOne);
      })
      .catch((err) => {
        console.log("Error uploading file", err);
        setIsUploadingFiles(false);
      });
  };

  const uploadImageTwo = (event) => {
    setIsUploadingFiles(true);
    const file = event.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 1024 * 1024; // 1MB

    if (fileSizeInBytes > maxSizeInBytes) {
      // File size exceeds the limit
      alert("Please select an image file smaller than 1MB.");
      return;
    }
    uploadToBucket(file)
      .then((thumb) => {
	console.log("Image uploaded successfully. URL:", thumb);
        setIsUploadingFiles(false);
        setImageTwo(thumb);
      })
      .catch((err) => {
        console.log("Error uploading file", err);
        setIsUploadingFiles(false);
      });
  };

  useEffect(() => {
    if (data) {
      setImageOne(data.newImage1);
      setImageTwo(data.newImage2);
      if (data.sensor_model === "河床堆積量計") {
        setdisplay(true);
      } else {
        setdisplay(false);
      }
      form.setFieldsValue(data);
    }
  }, [data]);

  return (
    <Modal
      centered
      // destroyOnClose
      title="ステーション登録/編集"
      className="custom-modal _user"
      open={show}
      onOk={submit}
      onCancel={onCancel}
      closable={false}
      okText={data ? "更新" : "登録"}
    >
      {isUploadingFiles && <LinearProgress />}
      <Form form={form} layout="vertical" initialValues={data}>
        {isUploadingFiles && <LinearProgress />}
        {hasFeature(userData, ACL.FEATURES.ALL_USERS) && (
          <Form.Item
            label="グループ"
            name="organizationId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="グループを選択してください"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={organizations.map((o) => ({
                value: o.id,
                label: o.name,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item
          label="デバイスID"
          name="device_id"
          rules={[
            { required: true },
            {
              pattern: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2})$/,
              message: "Please enter a valid device ID between 1 to 999.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {
          <Form.Item
            label="センサーの機種"
            name="sensor_model"
            rules={[{ required: true }]}
          >
            <Select
              id="model"
              onChange={standardDisplay}
              placeholder="センサーの機種を選択してください"
              optionFilterProp="children"
              // onChange={onChange}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "簡易流向計",
                  label: "簡易流向計",
                },
                {
                  value: "ゲート位置検出センサー",
                  label: "ゲート位置検出センサー",
                },
                {
                  value: "河床堆積量計",
                  label: "河床堆積量計",
                },
		{
                  value: "Default",
                  label: "Default",
                },
              ]}
            />
          </Form.Item>
        }
        {display && (
          <Form.Item
            label="基準値"
            name="standard_value"
            rules={[
              { required: true },
              {
                pattern: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2})$/,
                message:
                  "Please enter a valid standard value between 1 to 999.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="設置場所"
          name="deployed_location"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="River System"
          name="river_system"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="緯度・経度 Please enter a valid number!"
          name="lat_long"
          rules={[
            { required: true },
            {
              pattern:
                /^-?([1-8]?[1-9]|[1-9]0)(\.\d{1,15})?,\s?-?((1[0-7]|[1-9])?\d(\.\d{1,15})?|180(\.0{1,15})?)$/,
              message:
                'Please enter a valid latitude and longitude in the format "lat, long"!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="list-width">
          <label>写真１</label>
          <div className="thumb">
            <input
              className="u-full-width"
              type="file"
              value={data?.image1}
              name="images"
              accept="image/*"
              onChange={uploadImageOne}
            />
	    {imageOne && (
               <img src={imageOne} alt="" />
	    )}
          </div>
        </div>
        <div className="list-width">
          <label>写真２</label>
          <div className="thumb">
            <input
              className="u-full-width"
              type="file"
              value={data?.image2}
              name="images"
              accept="image/*"
              onChange={uploadImageTwo}
            />
            {imageTwo && <img src={imageTwo} alt="" />}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default OrganizationModal;
