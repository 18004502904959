import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Card } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { BiCloudDownload } from "react-icons/bi";
import { RiAdminLine } from "react-icons/ri";

import Map from "../components/Map";
import Graph from "../components/Graph";
import Databox from "../components/Databox";
import Image from "../components/Image";
import AppRight from "../components/AppRight";
import Header from "../components/Header";
import {
  stationList,
  stationData,
  stationHistoryData,
  selectedStationAtom,
} from "../store";
import Modal from "react-modal";
import DataDownload from "../components/DataDownload";
import ImageTab from "../components/ImageTab";

import "./Dashboard.scss";

function Dashboard() {
  let history = useHistory();
  const [allStations, getAllStations] = useAtom(stationList);
  const [, getStationData] = useAtom(stationData);
  const [, geHistoryData] = useAtom(stationHistoryData);
  const [, setSelectedStation] = useAtom(selectedStationAtom);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getAllStations();
    // getStationData(1);
    // geHistoryData(1);
    // setSelectedStation(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allStations.length) {
      initStation(allStations[0].device_id);
    }
  }, [allStations]);

  const initStation = (id) => {
    getStationData(id);
    geHistoryData(id);
    setSelectedStation(id);
  };

  return (
    <>
      <div className="Title">
        <Header scores={[1, 1, 1, 0]} />
      </div>
      <div className="app">
        <div className="app_left">
          <Map />
        </div>
        <div className="app_right">
          <AppRight props={history.location.state} />
          <Databox />
          <Card className="app_information">
            <Graph />
          </Card>
          <Card className="">
            {/*Removed class "app_information"*/}
            <ImageTab />
          </Card>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
