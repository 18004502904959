import React, { useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";

import { ACL } from "../../../constants";
import { useAtom } from "jotai";
import { user } from "../../../store";
import { hasFeature } from "../../../utilities/acl.utils";

const OrganizationModal = ({
  show,
  onSubmit,
  onCancel,
  data,
  organizations,
}) => {
  const [form] = Form.useForm();
  const [userData] = useAtom(user);

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch((err) => {
        console.log("form err", err);
      });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Modal
      centered
      destroyOnClose
      title="カメラ登録"
      className="custom-modal"
      open={show}
      onOk={submit}
      onCancel={onCancel}
      closable={false}
      okText={data ? "更新" : "登録"}
    >
      <Form form={form} layout="vertical" initialValues={data}>
        {hasFeature(userData, ACL.FEATURES.ALL_USERS) && (
          <Form.Item
            label="グループ"
            name="organizationId"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="グループ選択してください"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={organizations.map((o) => ({
                value: o.id,
                label: o.name,
              }))}
            />
          </Form.Item>
        )}

        <Form.Item
          label="デバイス Id"
          name="device_id"
          rules={[
            { required: true },
            {
              pattern: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9]{2})$/,
              message: "Please enter a valid device ID between 1 to 999.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="ユーザー名"
          name="username"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="パスワード"
          name="password"
          rules={[
            {
              required: true,
              message:
                "Please enter a valid half-width alphanumeric character.",
              pattern: /^[a-zA-Z0-9]+$/,
            },
          ]}
        >
          <Input />
          {/* <Input placeholder="Leave empty to keep unchanged" /> */}
        </Form.Item>

        <Form.Item
          label="IPアドレス'Please enter a valid URL in the format 'http://x.x.x.x:port/path'!"
          name="ip_add"
          rules={[
            { required: true },
            {
              pattern:
                /^http:\/\/((([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])):[0-9]{1,5}\/.*$/,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Interval"
          name="ip_interval"
          rules={[
            { required: true },
            {
              pattern: /^(?:[5-9]|[1-5][0-9]|60)$/,
              message: "Please enter a valid value between 5 and 60.",
            },
          ]}
        >
          <Input placeholder="Enter the time interval in minutes." />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default OrganizationModal;
