export default {
  FEATURES: {
    // Admin Page
    VIEW_ADMIN_PAGE: "VIEW_ADMIN_PAGE",

    // Stations
    ALL_STATIONS: "ALL_STATIONS",
    MANAGE_STATIONS: "MANAGE_STATIONS",
    VIEW_STATIONS: "VIEW_STATIONS",

    // User Management
    ALL_USERS: "ALL_USERS",
    MANAGE_USERS: "MANAGE_USERS",
    VIEW_USERS: "VIEW_USERS",

    // ADD/EDIT ORganizations
    MANAGE_ORGANIZATIONS: "MANAGE_ORGANIZATIONS",

    // Sensors
    // ALL_SENSORS: "ALL_SENSORS",
    // MANAGE_SENSORS: "MANAGE_SENSORS",
    // VIEW_SENSORS: "VIEW_SENSORS",

    //IPCameras
    ALL_IPCAMERAS: "ALL_IPCAMERAS",
    MANAGE_IPCAMERAS: "MANAGE_IPCAMERAS",
    VIEW_IPCAMERAS: "VIEW_IPCAMERAS",

    // ADD/REMOVE Recipient emails
    MANAGE_EMAILS: "MANAGE_EMAILS",
  },
  ROLES: {
    ROOT: "ROOT", // User for creating customers
    ADMIN: "ADMIN", // Organization Admin
    USER: "USER", // End User
  },
};
