import React, { useState } from "react";
import { Button, Table, Dropdown, Modal } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";

import StationModal from "./StationsModal";
import withAdmin from "../admin.provider";
import CameraList from "./CameraList";

import AWS from "aws-sdk";
const config = {
// accessKeyId: 'xxx',
// secretAccessKey: 'xxx',
 region: "ap-northeast-1",
};

const { confirm } = Modal;

const Stations = ({
  stations,
  organizations,
  createStation,
  editStation,
  disableStation,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editStationID, setEditStationID] = useState(null);

  const getDropdownItems = (id) => ({
    items: [
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              setEditStationID(id);
              setShowEditModal(true);
            }}
          >
            編集
          </div>
        ),
        key: `edit-org-${id}`,
      },
      {
        label: (
          <div
            className="clicker"
            onClick={() => {
              confirm({
                title: "Are you sure?",
                content: "Are you sure you want to delete this station?",
                onOk() {
                  disableStation(id);
                },
              });
            }}
          >
            削除
          </div>
        ),
        key: `delete-station-${id}`,
      },
    ],
  });

  const columns = [
    {
      title: "デバイス ID",
      dataIndex: "device_id",
      key: "device_id",
    },
    {
      title: "設置場所",
      dataIndex: "deployed_location",
      key: "deployed_location",
    },
    {
      title: "センサーの機種",
      dataIndex: "sensor_model",
      key: "sensor_model",
    },
    {
      title: "グループ",
      dataIndex: ["organization", "name"],
      key: "organization",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text, record) => (
        <Dropdown
          overlayClassName="list-actions"
          menu={getDropdownItems(record.device_id)}
          trigger={["click"]}
        >
          <span className="actions-btn">
            <BiDotsVerticalRounded />
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <div id="stations" className="admin-page">
      <div>
        {showCreateModal && (
          <StationModal
            show={showCreateModal}
            organizations={organizations || []}
            onCancel={() => setShowCreateModal(false)}
            onSubmit={(values) => {
              createStation(values);
              setShowCreateModal(false);
            }}
          />
        )}
        {showEditModal && (
          <StationModal
            show={showEditModal}
            organizations={organizations || []}
            data={stations.find(
              (station) => station.device_id === editStationID
            )}
            onCancel={() => setShowEditModal(false)}
            onSubmit={(values) => {
              editStation(editStationID, values);
              setShowEditModal(false);
              setEditStationID(null);
            }}
          />
        )}
        <div className="admin-page-header">
          <div className="header-title">ステーション</div>
          <div className="header-description">ステーション管理</div>
        </div>
        <div className="admin-page-body">
          <div className="admin-toolbar">
            <Button type="primary" onClick={() => setShowCreateModal(true)}>
              新規ステーション登録
            </Button>
          </div>
          <div className="admin-content">
            <Table
              dataSource={stations}
              columns={columns}
              pagination={{ pageSize: 3 }}
            />
          </div>
          <CameraList />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(Stations);
