import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Tooltip,
} from "@material-ui/core";
import "./DataDownload.css";
//import { Menu as MenuIcon } from '@material-ui/icons'
///import React, { useState, useEffect } from "react";
import { RiDownloadCloudLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import DataDownload from "./DataDownload";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

const Header = (props) => {
  const { classes } = props;
  let history = useHistory();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const ButtonView1 = (props) => {
    if (props.scores[0]) {
      return (
        <Tooltip
          placement="bottom"
          // title="Download"
          className="download-tooltip"
        >
          <Button color="inherit" onClick={() => setModalIsOpen(true)}>
            {/* <RiDownloadCloud2Line size={30} /> */}
            <RiDownloadCloudLine size={30} />
            {/* <RiDownloadCloudFill size={30} /> */}
          </Button>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };
  // const ButtonView2 = (props) => {
  //   if (props.scores[1]) {
  //     return (
  //       <Button
  //         className="header-button"
  //         color="inherit"
  //         onClick={() => history.push("/management")}
  //       >
  //         管理者画面
  //       </Button>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          />
          <Typography variant="h6" className={classes.title}>
            <Box textAlign="left">Webサーバー閲覧システム</Box>
          </Typography>
          <ButtonView1 {...props} /> {/*ボタン表示判定関数へ*/}
          {/* <ButtonView2 {...props} /> */}
        </Toolbar>
      </AppBar>

      <Modal className="download_modal" isOpen={modalIsOpen}>
        <div id="overlay" onClick={setModalIsOpenToFalse}>
          <div id="content" onClick={(e) => e.stopPropagation()}>
            <p>
              <DataDownload />
            </p>
            <p className="closebut">
              <button id="butfont" onClick={setModalIsOpenToFalse}>
                閉じる
              </button>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withStyles(useStyles)(Header);
