import React from "react";
import { useAtom } from "jotai";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { stationData } from "../store";

function DEFAULT() {
  const [Data] = useAtom(stationData);
  return (
    <>
      <CardContent className="taisa-card">
        <Grid container>
          <Grid item xs={12}>
            <Card inline className="data_card">
              {/* <CardHeader
                            className="data_card_header"
                            title={
                              <Typography variant="h6" align="center">
                                水位堆砂量計
                              </Typography>
                            }
                          /> */}
              <CardContent className="card_body">
                <Grid container className="water_card">
                  <Grid item xs={4} className="water_card_title">
                    <Typography variant="h5"> 角度</Typography>
                  </Grid>
                  <Grid item xs={8} align="center" className="water_data">
                    <Typography variant="h4">
                      {!Data
                        ? ""
                        : Data.length !== 0
                        ?Number(Data.h1_angle).toFixed(2) / 100
                        : ""}{" "}
                      degrees
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default DEFAULT;
