import React from "react";
import withAdmin from "../admin.provider";
import SensorStatus from "./SensorStatus";

const Downlinks = () => {
  // const deviceIds = cameras.map((camera) => camera.device_id);
  // const deviceIds = [1, 5, 20];
  // Reload the databox

  return (
    <div id="cameras" className="admin-page">
      <div className="admin-page-header">
        <div className="header-title">ステーション情報/操作</div>
      </div>
      <div className="admin-page-body">
        <div className="admin-content">
          <SensorStatus />
        </div>
      </div>
    </div>
  );
};

export default withAdmin(Downlinks);
