import React, { useEffect, useState } from "react";
import { Line, Scatter } from "react-chartjs-2";
import numeral from "numeral";
import { selectedStationAtom, stationList } from "../store"; // 2023.1019
import { useAtom } from "jotai";
import "./Graph.css";
import { Card, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
//import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { GLOBAL } from "../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "../utilities/axios.utils";

dayjs.extend(utc);
dayjs.extend(timezone);

function Graph() {
  let history = useHistory();

  const [selectedStation] = useAtom(selectedStationAtom);
  const [historyData, geHistoryData] = useState([]);

  // 2023.10.19 From
  const [stations] = useAtom(stationList);
  const [lastShouldShowGraph, setLastShouldShowGraph] = useState(false);
  const shouldShowGraph = stations.some(
    (item) =>
      item.device_id === selectedStation && item.sensor_model === "河床堆積量計"
  );

  useEffect(() => {
    setLastShouldShowGraph(shouldShowGraph);
  }, [shouldShowGraph]);
  // 2023.10.19 TO

  //現在日時
  var newDate = new Date();
  //現在日時-1日
  //newDate.setDate(newDate.getDate()-1);
  var newDate2 = new Date();
  //現在日時-1日
  newDate2.setDate(newDate2.getDate() - 1);

  const [startDate, setStartDate] = useState(newDate2);
  const [endDate, setEndDate] = useState(newDate);

  const maxDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  const today = new Date();

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="custom-datepicker-input" onClick={onClick}>
      <input type="text" value={value} readOnly />
    </div>
  );

  // Function to disable times before the selected start time
  const filterPassedTime = (time) => {
    if (startDate === null) {
      return false;
    }
    return time > startDate;
  };

  const minTimeForEndDate =
    startDate.getDate() === endDate.getDate()
      ? new Date(startDate.getTime() + 30 * 60 * 1000)
      : new Date(0, 0, 0, 0, 0);

  const handleErrors = (res) => {
    //console.log("Api-act4");
    if (res.ok) {
      return res;
    }
    switch (res.status) {
      case 400:
        throw Error("INVALID_TOKEN");
      case 401:
        throw Error("UNAUTHORIZED");
      case 500:
        throw Error("INTERNAL_SERVER_ERROR");
      case 502:
        throw Error("BAD_GATEWAY"); //stationTBLはあるがsenTBLない場合,全localのtest環境では何も返らず延々と応答待ちするが, 本番環境では500番台のerror返るので時間でerror判定とかはしない)
      case 404:
        throw Error("NOT_FOUND");
      default:
        throw Error("UNHANDLED_ERROR");
    }
  };

  const token = window.sessionStorage.getItem(GLOBAL.TOKEN_COOKIE_NAME);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      Timezone: "UTC",
    },
  };

//  const fetchWithErrorHandling = (url, options) =>
//    fetch(url, options)
      // 1.※ ネットワーク周りなどのリクエスト以前の段階でのエラーを処理する
//      .catch((e) => {
//        throw Error(e);
//      })
      // 2. サーバサイドで発行されたエラーステータスを処理する
//      .then(handleErrors)
      // 3. 以上2つをパスした正常なレスポンスからJSONオブジェクトをパースする
//      .then((res) => res.json());
  const fetchWithErrorHandling = (url, options) => axios.get(url, options);

  const unauthorizedRequest = async (id) => {
    if (selectedStation !== null) {
      try {
        // const sDate = moment(startDate).utc().format("yyyy-MM-DD HH:mm");
        // const eDate = moment(endDate).utc().format("yyyy-MM-DD HH:mm");
        const sDate = moment(startDate).format("YYYY-MM-DD HH:mm");
        const eDate = moment(endDate).format("YYYY-MM-DD HH:mm");
        //console.log("+++ ${selectedStation} +++ -> ", selectedStation);
        let ret = await fetchWithErrorHandling(
          `${process.env.REACT_APP_BACKEND_URL}/api/stations/${selectedStation}/graph/${sDate},${eDate}`,
          requestOptions
        );
        if (ret.success === true) {
          geHistoryData(ret.result);
        } else {
          geHistoryData([]);
        }
      } catch (e) {
        geHistoryData([]);
      }
    }
  };

  function getSenTblApi(id) {
    if (history.location.state) {
      history.push({
        state: {
          start: startDate,
          end: endDate,
          ImgId1: history.location.state.ImgId1,
          ImgId2: history.location.state.ImgId2,
          ImgId3: history.location.state.ImgId3,
        },
      });
    }
    unauthorizedRequest(id);
  }

  const options1 = {
    legend: {
      display: true,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          return numeral(tooltipItem.value).format("0.00");
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: "time",
          ticks: {
            min: startDate,
            max: endDate,
            minor: {
              fontSize: 10,
            },
          },

          time: {
            tooltipFormat: "MM/DD/YYYY HH:mm:ss",
            displayFormats: {
              millisecond: "MM/DD HH:mm",
              second: "MM/DD HH:mm",
              minute: "MM/DD HH:mm",
              hour: "MM/DD HH:mm",
              day: "MM/DD HH:mm",
              week: "MM/DD HH:mm",
              month: "MM/DD HH:mm",
              quarter: "MM/DD HH:mm",
              year: "MM/DD HH:mm",
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            min: 0,
            max: 6,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return numeral(value).format("0a");
            },
          },
        },
      ],
    },
  };

  const data1 = {
    labels: historyData.map((item) => {
      return item.date_time;
    }),
    datasets: [
      {
        label: "水位",
        data: historyData.map((item) => {
          // return item.h1_angle / 100;
          return Number(item.h1_angle).toFixed(2) / 100;
        }),
        fill: true,
        borderColor: "#0669AB",
      },
      {
        label: "堆積量",
        data: historyData.map((item) => {
          // return item.h2_angle / 100;
          return Number(item.h2_angle).toFixed(2) / 100;
        }),
        fill: true,
        backgroundColor: "#C26814",
        borderColor: "#C26814",
      },
    ],
  };

  useEffect(() => {
    // geHistoryData(selectedStation);
    // eslint-disable-next-line
    getSenTblApi(selectedStation);
    // eslint-disable-next-line
  }, [selectedStation]);

  useEffect(() => {
    getSenTblApi(selectedStation);
    // eslint-disable-next-line
  }, [startDate]);

  useEffect(() => {
    getSenTblApi(selectedStation);
    // eslint-disable-next-line
  }, [endDate]);

  return (
    <div>
      {lastShouldShowGraph && (
        <>
          <Grid container direction="row" spacing={24} justify="space-between">
            <Grid item xs={12}>
              <Card inline className="graph_card">
                <p style={{ textAlign: "center", margin: "10px 0 0 0" }}>
                  水位堆砂量計
                </p>
                <div>
                  <Line
                    data={data1}
                    height="220px"
                    width="200px"
                    options={options1}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
          <div className="button_item graph-datepicker">
            <Typography variant="h7" align="center">
              観測期間　
            </Typography>
            <DatePicker
              className="datetime_picker"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale="ja"
              dateFormat="yyyy年 MM月 dd日 HH:mm"
              maxDate={new Date()}
              showTimeSelect
              timeCaption="時間"
              timeFormat="HH:mma"
              timeIntervals={30}
              minTime={new Date(0, 0, 0, 0, 0)} // Set minimum time to 12am (0 hours)
              maxTime={
                startDate.getDate() === today.getDate()
                  ? maxDate
                  : new Date(0, 0, 0, 23, 59)
              } // Set maxTime conditionally
              customInput={<CustomDatePickerInput value={startDate} />}
            />
            　～　
            <DatePicker
              className="datetime_picker"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              locale="ja"
              dateFormat="yyyy年 MM月 dd日 HH:mm"
              minDate={startDate ? new Date(startDate.getTime()) : null}
              maxDate={new Date() < maxDate ? new Date() : maxDate}
              showTimeSelect
              timeCaption="時間"
              timeFormat="HH:mma"
              timeIntervals={30}
              // minTime={startDate}
              minTime={
                startDate.getDate() === endDate.getDate()
                  ? minTimeForEndDate
                  : new Date(0, 0, 0, 0, 0)
              }
              maxTime={
                endDate.getDate() === today.getDate()
                  ? new Date()
                  : new Date(0, 0, 0, 23, 59)
              } // Set maxTime for end date conditionally
              filterTime={filterPassedTime}
              customInput={<CustomDatePickerInput value={endDate} />}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Graph;
