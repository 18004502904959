import React from "react";
import { useAtom } from "jotai";
import { CardContent, Typography, Grid } from "@material-ui/core";
import { stationData } from "../store";
import {
  getStatusCard,
  CustomCardHeader,
  CustomSubCardHeader,
} from "./commonComponents";

function TSFDbottomsensor() {
  const [Data] = useAtom(stationData);

  return (
    <>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={10}
          justifyContent="space-evenly"
        >
          <Grid item xs={3}>
            <Grid container direction="row" item xs={12}>
              <CustomCardHeader
                title={
                  <Typography variant="body1" className="databox-font">
                    下段
                  </Typography>
                }
              />
            </Grid>
            <Grid container direction="row" item xs={12}>
              {!(Data.h2_angle === "****" || Data.h2_float_switch !== "1") && (
                <CustomSubCardHeader
                  title={
                    <Typography variant="body1" className="databox-font">
                      流向
                    </Typography>
                  }
                />
              )}
            </Grid>
          </Grid>

          <Grid container direction="row" item xs={7}>
            <Grid container direction="row" item xs={12}>
              {getStatusCard(
                Data.h2_float_switch === "1" ? "#87A4D9" : "#BFBFBF",
                Data.h2_float_switch === "1" ? "計測中" : "計測停止"
              )}
            </Grid>
            {!(Data.h2_angle === "****" || Data.h2_float_switch !== "1") && (
              <Grid container direction="row" item xs={12}>
                {["1", "0", "2"].map((flow_direction) => (
                  <Grid container item xs={4} key={flow_direction}>
                    {getStatusCard(
                      Data.h2_flow_direction === flow_direction
                        ? flow_direction === "2"
                          ? "#F3AD7D"
                          : flow_direction === "1"
                          ? "#87A4D9"
                          : "#BFBFBF"
                        : "white",
                      flow_direction === "1"
                        ? "順流"
                        : flow_direction === "0"
                        ? "停止"
                        : "逆流"
                    )}
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <div className="divider"></div>
    </>
  );
}

export default TSFDbottomsensor;
