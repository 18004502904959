import React, { useState, useEffect } from "react";
import { Card, Grid } from "@material-ui/core";
import { useAtom } from "jotai";

import { selectedStationAtom, stationList } from "../store";

import "./Image.css";

function Image() {
  const [selectedStation] = useAtom(selectedStationAtom);
  const [stations] = useAtom(stationList);
  const [stationData, setStationData] = useState();

  useEffect(() => {
    const station = stations.find((s) => s.device_id === selectedStation);
    setStationData(station);
  }, [selectedStation, stations]);

  useEffect(() => {
    const station = stations.find((s) => s.device_id === selectedStation);
    setStationData(station);
  }, []);

  console.log("Selected Station", selectedStation);

  return (
    <>
      {stationData && (
        <div>
          <div className="image_area">
            <Grid
              container
              direction="row"
              item
              xs={12}
              justifyContent="space-between"
            >
              <Grid container item xs={6}>
	      {/* <a
                  href={stationData.newImage1}
                  target="_blank"
                  rel="noreferrer"
                >*/}
                  <img
                    className="image station_image"
                    src={stationData.newImage1}
                    alt=""
                  />
	      {/*</a>*/}
              </Grid>
              <Grid container item xs={6}>
	      {/*<a
                  href={stationData.newImage2}
                  target="_blank"
                  rel="noreferrer"
                >*/}
                  <img
                    className="image station_image"
                    src={stationData.newImage2}
                    alt=""
                  />
	      {/*</a>*/}
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

export default Image;
