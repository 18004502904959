import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { Menu } from "../components/Menu";

import { AdminPage } from "../views/Admin/Admin";
import Dashboard from "../views/Dashboard";
import LoginPage from "../views/LoginPage";
import PrivateRoute from "./ProtectedRoute";

const Routes = () => {
  return (
    <Router>
      <Menu />
      <div className="app-body">
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/" exact>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <AdminPage />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
