import React, { useEffect, useState } from "react";

import L, { Popup } from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
} from "react-leaflet";
import { useAtom } from "jotai";
import socketIOClient from "socket.io-client";
import TSFDICON from "./Icons/TSFD.svg";
import TAISAICON from "./Icons/TAISA.svg";
import BOTHICON from "./Icons/BOTH.svg";
import { stationList, selectedStationAtom, stationData } from "../store";
import "./Map.css";
const SOCKEENDPOINT = process.env.REACT_APP_BACKEND_URL;

function FlyMapTo(props) {
  const map = useMap();
  useEffect(() => {
    map.flyTo(props.center, map.getZoom()); //props.zoom);
  });
  return null;
}

function Legend() {
  // const map = useMap();
  const position = "bottomleft";

  return (
    <div
      className={`leaflet-control leaflet-control-custom leaflet-${position}`}
    >
      <div className="leaflet-control-layers leaflet-control-layers-expanded">
        <div className="leaflet-control-layers-body">
          <div>
            <img src={TSFDICON} className="icon" alt="簡易流向計" />
            <span>簡易流向計</span>
            <br></br>
            <img
              src={BOTHICON}
              className="icon "
              alt="ゲート位置検出センサー"
            />
            <span>ゲート位置検出センサー</span>
            <br></br>
            <img src={TAISAICON} className="icon" alt="河床堆積量計" />
            <span>河床堆積量計</span>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}

function Map() {
  //const [Data] = useAtom(stationData);
  const [stations] = useAtom(stationList);
  const [selectedStation, setSelectedStation] = useAtom(selectedStationAtom);
  const [, getStationData] = useAtom(stationData);
//  const [lat_long, setlat_long] = useState([
//    32.71776829575492, 130.64599818998556,
//  ]);
  const [lat_long, setlat_long] = useState();

  const newSelstation = stations.filter((selstation) => {
    return selstation.device_id === selectedStation;
  });

  useEffect(() => {
    getStationData(selectedStation);
    const socket = socketIOClient(SOCKEENDPOINT);
    socket.connect();
    socket.on(`${selectedStation}`, (data) => {
      console.log("Realtime Data", data);
      getStationData(selectedStation);
    });
    if (newSelstation.length) {
      setlat_long(newSelstation[0].lat_long.split(","));
    }
    return () => socket.disconnect();
  }, [selectedStation]);

  return (
    <>
      {stations && lat_long && (
        <MapContainer
          center={lat_long}
          zoom={10}
          maxZoom={14}
          minZoom={5}
          scrollWheelZoom={true}
        >
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
            integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
            crossOrigin=""
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
          />
          <TileLayer
            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            attribution='<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank">地理院タイル</a>'
          />
          {stations.map((station, idx) => (
            <Marker
              key={idx}
              eventHandlers={{
                click: (e) => {
                  setSelectedStation(station.device_id);
                },
              }}
              icon={
                station.sensor_model === "簡易流向計"
                  ? L.divIcon({
                      className: `blinking1 ${
                        station.device_id === selectedStation
                          ? "blink-circle"
                          : ""
                      }`,
                      // iconSize: [100, 100], // Adjust the size as needed
                      html: `
                 <img src="${TSFDICON}" width="42" height="42" />
                `,
                    })
                  : station.sensor_model === "ゲート位置検出センサー"
                  ? L.divIcon({
                      className: `blinking1 ${
                        station.device_id === selectedStation
                          ? "blink-circle"
                          : ""
                      }`,
                      html: `
                 <img src="${BOTHICON}" width="42" height="42" />
                `,
                    })
                  : L.divIcon({
                      className: `blinking1 ${
                        station.device_id === selectedStation
                          ? "blink-circle"
                          : ""
                      }`,
                      html: `
                 <img src="${TAISAICON}" width="42" height="42" />
                  `,
                    })
              }
              position={station.lat_long.split(",")}
            >
              {/* <Tooltip>
                <h2>{station.deployed_location}</h2>
              </Tooltip> */}
            </Marker>
          ))}
          <Legend />
          <FlyMapTo center={lat_long} />
        </MapContainer>
      )}
    </>
  );
}

export default Map;

