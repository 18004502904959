import React from "react";
import { useState } from "react";
import { useAtom } from "jotai";
import "./Pop-up.css";
import TSFD from "./TSFD";
import TAGF from "./TAGF";
import TAISA from "./TAISA";
import DEFAULT from "./DEFAULT";
import { TfiReload } from "react-icons/tfi";
import { RxInfoCircled } from "react-icons/rx";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
} from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { stationData, stationList, selectedStationAtom } from "../store";
import "./Databox.css";
import { Button, Tooltip } from "antd";

dayjs.extend(utc);
dayjs.extend(timezone);

function Databox() {
  const [Data] = useAtom(stationData);
  const [stations] = useAtom(stationList);
  const [selectedStation] = useAtom(selectedStationAtom);
  let showDataIntervalError;
  let result;
  const currentTokyoTime = dayjs.tz(dayjs(), "Asia/Tokyo");
  const selectedStationData = stations.find(
    (s) => s.device_id === selectedStation
  );
  const interval = selectedStationData?.organization?.communication_interval;

  const sensorTime = dayjs(Data.createdAt);
  // console.log("Created time; ", sensorTime);
  if (sensorTime.isBefore(currentTokyoTime)) {
    showDataIntervalError = sensorTime.diff(currentTokyoTime, "minutes");
    result = Math.abs(showDataIntervalError);
  }
  console.log("sensorTime", sensorTime);
  // Reload the databox
  const [refresh, setRefresh] = useState(0);
  const handleRefresh = () => {
    setRefresh((prevRefresh) => prevRefresh + 1);
  };

  // Add error class if signal includes error code
  const classNames = ["card_header1"];
  //if (result === "信号異常") {
    // classNames.push("error");
  //}
  if (result > interval) {
  classNames.push("error");
  } 
  const className = classNames.join(" ");

  // Sensor type and display the selected value
  const sensorComponents = {
    "0001": <TSFD />,
    "0002": <TAGF />,
    "0003": <TAISA />,
    "0004": <DEFAULT/>,
  };
  const selectedValue =
    selectedStation === Data.device_id ? (
      sensorComponents[Data.pan_id] || null
    ) : (
      <></>
    );

  // Tootltip for each sensor type
  var tooltiptest;
  switch (Data.pan_id) {
    case "0001":
      tooltiptest =
        "本センサーは、河川や流路の流向を検知アームの傾きから簡易に検出する簡易流向センサーです。河川や流路の護岸等壁面に設置し、流速に応じた検知アームの傾きにより流向を判断し出力します。";
      break;
    case "0002":
      tooltiptest =
        Data.h2_angle !== "****"
          ? "ゲート位置検出センサー・簡易流向計"
          : "本センサーは加速度センサー式のゲート位置検出センサーであり、フラップゲートに設置します。開度に応じ回転するセンサーの鉛直方向からの傾き角度を計測し、必要な開度を算出するためのものです。";
      break;
    case "0003":
      tooltiptest =
        "本装置は、河床の堆積量と水位を同時に観測する直接観測式のセンサーです。河床の堆積物と河川水の導電率をもとに堆砂量と水位の計測を行います。";
      break;
    default:
      tooltiptest =
        "センサーはWebに登録されましたが、ロガーとデータベースの間の通信はまだ行われていません。";
      break;
  }

  return (
    <>
      <Card className="app_information TSFD ">
        {/*pop-up class removed*/}
        <CardHeader
          className={className}
          title={
            <Grid container justifyContent="space-between">
              <Typography variant="h6" align="left">
                {stations.length !== 0 && selectedStation
                  ? stations.find(
                      (station) => station.device_id === selectedStation
                    )["deployed_location"]
                  : ""}
                <Tooltip title="Reload">
                  <Button
                    onClick={handleRefresh}
                    style={{
                      background: "none",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <TfiReload size={15} />
                  </Button>
                </Tooltip>
              </Typography>
	      <Typography variant="h6" align="left">
              <Tooltip title={tooltiptest}>
                <RxInfoCircled size={20} />
              </Tooltip>
              </Typography>
            </Grid>
          }
        />
        <div className="container">
          <div className="sub-container">
            {/* //## Datetime Card Header */}
            <CardContent style={{ margin: "10px 0 0 0" }}>
              <Grid
                container
                direction="row"
                spacing={10}
                justifyContent="space-evenly"
              >
                <Grid item xs={3}>
                  <CardHeader
                    className="up_card_header"
                    title={
                      <Typography variant="body1" className="databox-font">
                        最新観測
                      </Typography>
                    }
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={7}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    direction="column"
                    itemq
                    xs={7}
                    className="status_card"
                  >
                    {(() => {
                      if (selectedStation === Data.device_id) {
                        return (
                          <>
                            {/* {showDataIntervalError && (
                              <div>
                                Sensor time is {result} minutes behind current
                                time by
                              </div>
                            )} */}
                            <Typography
                              variant="body1"
                              className="databox-font"
                            >
                              {Data.length !== 0
                                ? dayjs(Data.date_time).format(
                                    "YYYY/MM/DD HH:mm"
                                    // "YYYY/MM/DD h:mm A"
                                  )
                                : ""}
                            </Typography>
                          </>
                        );
                      } else {
                        return "";
                      }
                    })()}
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs={5}
                    className="status_card"
                  >
                    <Card
                      className="status_card"
                      style={{
                        backgroundColor:
                          result < interval ? "#AFD498" : "#FF5353",
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" align="center">
                          {}
                          {Data.length !== 0
                            ? result < interval //minutes
                              ? "信号正常"
                              : "信号異常"
                            : "..."}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid
                container
                direction="row"
                spacing={10}
                justifyContent="space-evenly"
              >
                <Grid item xs={3}>
                  <CardHeader
                    className="up_card_header"
                    title={
                      <Typography variant="body1" className="databox-font">
                        状態
                      </Typography>
                    }
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  item
                  xs={7}
                  justifyContent="space-between"
                >
                  <div container direction="column" item xs={12}>
                    <Card
                      className="status_card"
                      style={{
                        backgroundColor:
                          Data.error_code === "00" ? "#AFD498" : "#FF5353",
                      }}
                    >
                      <CardContent align="center">
                        <Typography variant="body1">
                          {selectedStation === Data.device_id ? (
                            (() => {
                              const textMapping = {
                                "00": "正常",
                                10: "入力信号エラー(1 量)",
                                20: "入力信号エラー(2 量)",
                                30: "入力信号エラー(1 量・2 量)",
                                "01": "電圧低下",
                              };

                              return textMapping[Data.error_code] ? (
                                <>{textMapping[Data.error_code]}</>
                              ) : null;
                            })()
                          ) : (
                            <>通信なし</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <div className="divider"></div>
            {selectedValue}
          </div>
        </div>
      </Card>
    </>
  );
}

export default Databox;
