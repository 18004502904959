/* eslint no-unused-vars: */

import React, { useEffect, useState } from "react";
import { message, Input, Button, Form } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";

import { user } from "../store";
import { GLOBAL } from "../constants";
import "./LoginPage.scss";

function LoginPage() {
  const history = useHistory();
  const [userData, setUserData] = useAtom(user);

  useEffect(() => {
    if (userData) {
      history.push("/");
    }
  }, [userData]);

  const handleLogin = ({ email, password }) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
        email,
        password,
      })
      .then(({ data }) => {
        if (data.error) {
          message.error(data.message);
        } else {
          setUserData(data.user);
          window.sessionStorage.setItem(
            GLOBAL.TOKEN_COOKIE_NAME,
            data.accessToken
          );
          history.push("/");
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data?.message);
        } else {
          console.log(err);
          message.error("Unexpected error. Please refresh and try again.");
        }
      });
  };

  return (
    <div id="login-page">
      <div className="login-form">
        <h2>Login</h2>
        <Form onFinish={handleLogin} name="login_form">
          <Form.Item
            rules={[
              { required: true, message: "Please enter your email address" },
            ]}
            name="email"
          >
            <Input
              className="form-input"
              placeholder="Email Address"
              type="email"
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please enter your password" }]}
            name="password"
          >
            <Input.Password className="form-input" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default LoginPage;
