import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { initializeApp, getApps } from 'firebase/app';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import 'antd/dist/reset.css';
import './index.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
}

if (getApps().length < 1) {
  initializeApp(firebaseConfig);
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Router>
        <App/>
      </Router>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
