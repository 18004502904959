import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Button,
} from "@material-ui/core";
import "./DataDownload.css";
import FileDownload from "js-file-download";
// import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { stationList, selectedStationAtom } from "../store";
import { useAtom } from "jotai";
import moment from "moment";
import { GLOBAL } from "../constants";

function DataDownload(props) {
  // const thirtyDaysAgo = new Date();
  // thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  // thirtyDaysAgo.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [stations] = useAtom(stationList);
  const [selectedStation] = useAtom(selectedStationAtom);
  const maxDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  const today = new Date();

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="custom-datepicker-input" onClick={onClick}>
      <input type="text" value={value} readOnly />
    </div>
  );

  // Function to disable times before the selected start time
  const filterPassedTime = (time) => {
    if (startDate === null) {
      return false;
    }
    return time > startDate;
  };

  const minTimeForEndDate =
    startDate.getDate() === endDate.getDate() //This condition checks if the day (date component) of startDate is the same as the day of endDate. It compares the date components of these two Date objects.
      ? new Date(startDate.getTime() + 30 * 60 * 1000) //If the condition is true, meaning that startDate and endDate have the same date, it calculates a new date and time by adding 30 minutes (30 * 60 * 1000 milliseconds) to startDate.
      : new Date(0, 0, 0, 0, 0);

  const currentDate = new Date(); // Get the current date
  const timeDifference = startDate.getTime() - currentDate.getTime();

  // const maxTimeForEndDate =
  //   startDate.getDate() === endDate.getDate() // If startDate and endDate have the same date
  //     ? new Date(startDate.getTime() + 30 * 60 * 1000) // 30 min later
  //     : // If startDate and endDate have different dates
  //     timeDifference < 30 * 24 * 60 * 60 * 1000 // If the difference is less than 30 days
  //     ? currentDate // Set to the current date
  //     : new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days later

  console.log(minTimeForEndDate);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const token = window.sessionStorage.getItem(GLOBAL.TOKEN_COOKIE_NAME);
      const currentStation =
        stations.length !== 0 && selectedStation
          ? stations.find((station) => station.device_id === selectedStation)[
              "deployed_location"
            ]
          : "";
      const sDate = moment(startDate).format("YYYY-MM-DD HH:mm");
      const eDate = moment(endDate).format("YYYY-MM-DD HH:mm");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          Timezone: "UTC",
        },
      };

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/stations/download/${selectedStation}/${sDate},${eDate}/csv`,
        requestOptions
      )
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          if (blob) {
            FileDownload(blob, `${currentStation}_${sDate}${eDate}.csv`);
          }
        })
        .catch((err) => {
          return Promise.reject({ Error: "Something Went Wrong", err });
        });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Card className="download_card">
      <CardHeader
        className="card_header"
        title={
          <Typography variant="h6" align="center">
            データダウンロード
          </Typography>
        }
      />

      <CardContent>
        <form onSubmit={handleSubmit}>
          <li className="list_class">
            開始時期 &nbsp;
            <DatePicker
              className="datetime_picker"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale="ja"
              dateFormat="yyyy年 MM月 dd日 HH:mm"
              maxDate={new Date()}
              showTimeSelect
              timeCaption="時間"
              timeFormat="HH:mma"
              timeIntervals={30}
              minTime={new Date(0, 0, 0, 0, 0)} // Set minimum time to 12am (0 hours)
              maxTime={
                startDate.getDate() === today.getDate()
                  ? maxDate
                  : new Date(0, 0, 0, 23, 59)
              } // Set maxTime conditionally
              customInput={<CustomDatePickerInput value={startDate} />}
            />
          </li>

          <li className="list_class">
            終了日時 &nbsp;
            <DatePicker
              className="datetime_picker"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              locale="ja"
              dateFormat="yyyy年 MM月 dd日 HH:mm"
              minDate={startDate ? new Date(startDate.getTime()) : null}
              maxDate={new Date() < maxDate ? new Date() : maxDate}
              showTimeSelect
              timeCaption="時間"
              timeFormat="HH:mma"
              timeIntervals={30}
              // minTime={startDate}
              minTime={
                startDate.getDate() === endDate.getDate()
                  ? minTimeForEndDate
                  : new Date(0, 0, 0, 0, 0)
              } //If startDate and endDate have the same date component (day), it sets minTime to minTimeForEndDate, which appears to be a variable or value specific to your application. Otherwise, it sets minTime to a Date object representing midnight (00:00) on January 1, 0000.
              maxTime={
                endDate.getDate() === today.getDate()
                  ? new Date()
                  : new Date(0, 0, 0, 23, 59)
              } // Set maxTime for end date conditionally
              filterTime={filterPassedTime}
              // minTime={new Date(0, 0, 0, 0, 0)} // Set minimum time to 12am (0 hours)
              // maxTime={new Date(0, 0, 0, 23, 59)} // Set maximum time to 11:59pm (23 hours and 59 minutes)
              customInput={<CustomDatePickerInput value={endDate} />}
            />
          </li>
          <li className="button_item">
            <Button
              className="download_button"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              ダウンロード
            </Button>
          </li>
        </form>
      </CardContent>
    </Card>
  );
}

export default DataDownload;
