import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "../../../utilities/axios.utils";
import { Col, Row } from "antd";
import { getS3Client } from '../../../utilities/bucket.utils'; //20240205

const BucketList = ({ folders }) => {
  const [latestImages, setLatestImages] = useState([]);
  //const [selectedDate] = useState(new Date());
  //const currentDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); //this is used for hiding the image url
  const history = useHistory();

//  useEffect(() => {
//    const s3 = new AWS.S3({
//      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
//      secretAccessKey: process.env.REACT_APP_S3_SECRET,
//      credentials: credentials,
//      region: "ap-northeast-1",
//    });
//    console.log('ReactS3Client:', credentials);


  useEffect(() => {
    const initializeS3 = async () => {
      try {
        const credentials = await getS3Client(); // Assuming getS3 returns the required credentials
        const s3 = new AWS.S3({
          credentials: credentials,
          region: "ap-northeast-1",
        });
//        console.log('ReactS3:', credentials);
        // Rest of your S3-related code
//      } catch (error) {
//        console.error('Error initializing S3:', error);
//      }
//    };

//    initializeS3();
 // }, []); // Empty dependency array to ensure useEffect runs only once



    const getLatestImages = async (folders) => {
      const latestImagesArray = [];

      for (const folder of folders) {
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Prefix: folder + "/",
          // Prefix: folder + "/" + currentDate + "/",
        };

        try {
          const data = await s3.listObjectsV2(params).promise();

          if (data.Contents.length > 0) {
            const latestImage = data.Contents.reduce(
              (latest, obj) => {
                const objKey = obj.Key.toLowerCase();
                const isImage = objKey.endsWith(".jpg");
                const isAfter = objKey > latest.Key;
                return isImage && isAfter ? obj : latest;
              },
              { Key: "" }
            );

            const imageKey = latestImage.Key.replace(folder, "");
            const imageUrl = s3.getSignedUrl("getObject", {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: latestImage.Key,
              Expires: 60 * 5, // 5 minutes
            });

            const result = await axios(`/api/stations/${folder}`);

            latestImagesArray.push({
              folderName: folder,
              imageUrl: imageUrl,
              imageKey: imageKey,
              DeployedLocation: result.result.deployed_location,
              DeviceId: result.result.device_id,
            });
          } else {
            // Use default folder and default image when requested folder is not found
            const defaultImageKey = "default_image_key.jpg"; // Provide the default image key
            const defaultImageUrl = s3.getSignedUrl("getObject", {
              Bucket: process.env.REACT_APP_BUCKET,
              Key: defaultImageKey,
              Expires: 60 * 5, // 5 minutes
            });

            latestImagesArray.push({
              folderName: folder,
              imageUrl: defaultImageUrl,
              imageKey: defaultImageKey,
            });
          }
        } catch (error) {
          console.error(`Error fetching images for folder ${folder}:`, error);
        }
      }

      setLatestImages(latestImagesArray);
    };

    if (folders.length > 0) {
      getLatestImages(folders);
    }

    } catch (error) {
        console.error('Error initializing S3:', error);
      }
    };

    initializeS3();	  
  }, [folders]);

  const handleClick = (imageUrl, folder) => {
    setSelectedImageUrl(imageUrl);
    history.push(`/admin/cameras/IPcameraHIstory/${folder}`, "_blank");
  };

  const handleNewWindowLoad = (event) => {
    event.source.postMessage(selectedImageUrl, event.origin);
  };

  return (
    // </div>
    <div className="admin-page">
      <div className="admin-page-body">
        <div className="bucket-content">
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {latestImages.map((image) => (
              <Col className="gutter-row" span={6}>
                <div className="bucket-list-title" key={image.folderName}>
                  <h2>
                    {image.DeployedLocation} [Id:{image.DeviceId}]
                  </h2>
                  <div className="image-grid">
                    {latestImages
                      .filter((img) => img.folderName === image.folderName)
                      .map((img, index) => (
                        <div
                          key={index}
                          className="latest-img"
                          onClick={() =>
                            handleClick(img.imageUrl, img.folderName)
                          }
                        >
                          <img src={img.imageUrl} alt="画像読み込み" />
                        </div>
                      ))}
                  </div>
                </div>
              </Col>
            ))}
            {selectedImageUrl && (
              <iframe
                src={`/admin/cameras/IPcameraHIstory/${selectedImageUrl}`}
                style={{ display: "none" }}
                onLoad={handleNewWindowLoad}
              />
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BucketList;
