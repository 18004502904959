import React from "react";
import { Card, CardContent, Typography, CardHeader } from "@material-ui/core";

export const getStatusCard = (backgroundColor, text) => (
  <Card className="status_card" style={{ backgroundColor }}>
    <CardContent align="center">
      <Typography variant="body1">{text}</Typography>
    </CardContent>
  </Card>
);

export const CustomCardHeader = ({ title }) => (
  <CardHeader className="up_card_header" title={title} />
);

export const CustomSubCardHeader = ({ title }) => (
  <CardHeader className="up_card_header sub-card-header" title={title} />
);
