import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Spin } from "antd";
import { AiOutlineLoading } from "react-icons/ai";

import Routes from "./routes/routes";
import { GLOBAL } from "./constants";
import { appLoadingAtom, user } from "./store";
import axios from "./utilities/axios.utils";

import "./App.scss";

const App = () => {
  const container = document.getElementById("root");
  const [appLoading, setAppLoading] = useAtom(appLoadingAtom);
  const [, setUser] = useAtom(user);
  const [initialized, setInitialized] = useState(false);

  // Check if use is still in session
  useEffect(() => {
    const accessToken = window.sessionStorage.getItem(GLOBAL.TOKEN_COOKIE_NAME);
    if (accessToken) {
      // User is still in session and app has been refreshed
      // Login and get user data
      axios.post("auth/login/token").then(({ user, accessToken }) => {
        setUser(user);
        window.sessionStorage.setItem(GLOBAL.TOKEN_COOKIE_NAME, accessToken);
        setAppLoading(false);
        setInitialized(true);
      });
    } else {
      // New Session
      setAppLoading(false);
      setInitialized(true);
    }
  }, []);

  return (
    <div id="app-wrap">
      {appLoading && (
        <div id="app-loading">
          <Spin className="rotate" indicator={<AiOutlineLoading />} />
        </div>
      )}
      {initialized && !appLoading && <Routes />}
    </div>
  );
};

export default App;
