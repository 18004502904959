import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FiUsers } from "react-icons/fi";
import { MdSensors, MdLogout } from "react-icons/md";
import { GiCctvCamera } from "react-icons/gi";
import { useAtom } from "jotai";
import { AiOutlineHome } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { RiArrowUpDownLine } from "react-icons/ri";
import { user } from "../../store";
import { ACL, GLOBAL } from "../../constants";
import { hasFeature } from "../../utilities/acl.utils";
import "./Menu.scss";

const MainMenu = () => {
  const [userData, setUserData] = useAtom(user);
  const [menuItems, setMenuItems] = useState([]);
  const history = useHistory();

  const menuItemsAll = {
    home: {
      key: "menu-home",
      label: "ホーム",
      icon: <AiOutlineHome />,
    },
    organizations: {
      key: "menu-organizations",
      label: "登録グループ",
      icon: <HiOutlineBuildingOffice2 />,
    },
    users: {
      key: "menu-users",
      label: "登録ユーザー",
      icon: <FiUsers />,
    },
    stations: {
      key: "menu-stations",
      label: "登録ステーション",
      icon: <MdSensors />,
    },
    cameras: {
      key: "menu-cameras",
      label: "設置カメラ",
      icon: <GiCctvCamera />,
    },
    stations_status: {
      key: "menu-StationStatus",
      label: "ステーション情報/操作",
      icon: <RiArrowUpDownLine />,
    },
    logout: {
      key: "menu-logout",
      label: "ログアウト",
      icon: <MdLogout />,
    },
  };
  const logout = () => {
    setUserData(null);
    window.sessionStorage.removeItem(GLOBAL.TOKEN_COOKIE_NAME);
    // window.location.reload();
  };

  const makeMenu = () => {
    const menu = [];
    menu.push(menuItemsAll.home);

    if (hasFeature(userData, ACL.FEATURES.MANAGE_ORGANIZATIONS)) {
      menu.push(menuItemsAll.organizations);
    }

    if (hasFeature(userData, ACL.FEATURES.MANAGE_USERS)) {
      menu.push(menuItemsAll.users);
    }

    if (hasFeature(userData, ACL.FEATURES.VIEW_STATIONS)) {
      menu.push(menuItemsAll.stations);
    }

    // menu.push(menuItemsAll.stations);
    menu.push(menuItemsAll.cameras);
    menu.push(menuItemsAll.stations_status);
    menu.push(menuItemsAll.logout);

    setMenuItems(menu);
  };

  useEffect(() => {
    makeMenu();
  }, [userData]);

  const handleMenuClick = (key) => {
    switch (key) {
      case "menu-logout":
        return logout();

      case "menu-home":
        return history.push("/");

      case "menu-organizations":
        return history.push("/admin/organizations");

      case "menu-users":
        return history.push("/admin/users");

      case "menu-stations":
        return history.push("/admin/stations");

      case "menu-cameras":
        return history.push("/admin/cameras");

      case "menu-StationStatus":
        return history.push("/admin/downlink");

      default:
        return false;
    }
  };

  if (!userData) {
    return null;
  }

  return (
    <div id="main-menu">
      <Menu
        items={menuItems}
        mode="inline"
        theme="dark"
        onClick={({ key }) => handleMenuClick(key)}
      />
      <div className="menu-bottom">
        <div className="org-name">{userData?.organization?.name}</div>
        <div className="org-name">{userData?.organization?.email}</div>
      </div>
    </div>
  );
};

export default MainMenu;
