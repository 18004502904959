import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAtom } from "jotai";
import { user } from "../../store";
import { Organizations } from "./Organizations";
import { Users } from "./Users";
import { Stations } from "./Stations";
import { hasFeature } from "../../utilities/acl.utils";
import { ACL } from "../../constants";
import "./Admin.scss";
import { Cameras } from "./Camera";
import IPCameraHistory from "./Camera/IPcameraHistory";
import { Downlinks } from "./Downlinks";

const AdminPage = () => {
  const [userData] = useAtom(user);

  return (
    <Switch>
      {hasFeature(userData, ACL.FEATURES.MANAGE_ORGANIZATIONS) && (
        <Route path={`/admin/organizations`} exact>
          <Organizations />
        </Route>
      )}
      <Route path={`/admin/users`} exact>
        <Users />
      </Route>
      <Route path={`/admin/stations`} exact>
        <Stations />
      </Route>
      <Route path={`/admin/cameras`} exact>
        <Cameras />
      </Route>
      <Route path={`/admin/cameras/ipcamerahistory/:device_id`} exact>
        <IPCameraHistory />
      </Route>
      <Route path={`/admin/downlink`} exact>
        <Downlinks />
      </Route>
    </Switch>
  );
};

export { AdminPage };
